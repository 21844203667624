.product_item {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15), 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #fff;
    min-height: 440px;
    position: relative;
}

.product_image {
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #e9e9e9;
    height: 160px;
}

.product_image::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.3s all ease;
    z-index: 2;
}

.product_image:hover::after {
    height: 100%;
}

.product_image:hover .slide-button {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.product_image img {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.product_image .slide-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    pointer-events: none;
    transition: 0.3s all ease !important;
    white-space: nowrap;
}

.product_item h3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-transform: uppercase;
}

.product_desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.product_item-meta span:nth-of-type(n + 2) {
    position: relative;
    margin-left: 34px;
}

.product_item-meta span:nth-of-type(n + 2)::before {
    position: absolute;
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background: #6c757d;
    top: 54%;
    left: -30px;
    transform: translateY(-50%);
    border-radius: 50%;
}

.product_item-action {
    position: absolute;
    width: 100%;
    bottom: 16px;
    padding: 16px 16px 0 16px;
}

.product_item-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 55%;
    flex-shrink: 0;
    line-height: 1.3;
}
