@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

:root {
    --text-gray: #6c757d;
    --color-main: #2879ff;
    --primary: #096eff;
    --link_color: #096eff;
    --info_color: #096eff;
    --success_color: #4caf50;
    --warning_color: #ff9800;
    --error_color: #f44336;
    --pending_color: #e6c300;
    --sub_text: #7a869a;
    --sub_text2: #6e7b91;
    --default: #d9d9d9;
    --bg_header_table: #fafafa;
    --border: #e5e7eb;
    --bg_main: #f3f6f9;
    --bg-header-dark: #001529;
    --bg_dark_card_default: #001529;
    --bg_dark_card: #374151;
    --bg_dark_card_2: #48556a;
    --bg-dark_input: #48556a;
    --bg-dark_input_hover: #3e495b;
    --bg-dark_input_text: #fff;
    --bg-dark_input_placeholder: #7a869a;
    --border-dark: #5b6371;
    --bg-btn-default-hover: #343d4c;
    --default_color: #3f4254;
    --border-antd: #d9d9d9;
    --border-table-antd: #f0f0f0;
    --main__color: #096eff;
    --sub_text_rgb: 107, 114, 128;
}

a {
    color: var(--link_color);
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
}

@media (pointer: coarse) {
    ::-webkit-scrollbar {
        height: 3px;
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
    }
}

.ant-pagination-item-active {
    background: var(--primary) !important;
    border-color: 1px solid #d9d9d9 !important;
    font-weight: 600 !important;
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
    color: #fff !important;
}

.ant-pagination-item,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-options .ant-select-selector,
.ant-pagination-prev .ant-pagination-item-link {
    border-radius: 5px !important;
}

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 22px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    margin-top: 20px;
}

.ant-modal-confirm .ant-modal-content {
    border-radius: 20px;
}

.ant-timeline .ant-timeline-item-content {
    color: var(--sub_text);
}

.disabled-row {
    background-color: rgba(0, 0, 0, 0.03);
}

label {
    display: block;
    font-size: 14px;
    line-height: 2;
    margin-bottom: 5px;
    text-align: left;
}

.required:after {
    color: #ff4d4f;
    content: '*';
    display: inline-block;
    font-family: SimSun, sans-serif;
    font-size: 14px;
    line-height: 1;
    margin-left: 2px;
}

.table-define {
    border: 1px solid var(--border);
    border-spacing: 0;
}

.table-define > thead > tr > th {
    background-color: #fafafa;
    border-bottom: 1px solid var(--border);
    overflow-wrap: break-word;
    padding: 16px;
    position: relative;
    transition: background 0.3s;
}

.table-define .cell-table-define:after {
    background-color: #f0f0f0;
    bottom: 12px;
    content: '';
    position: absolute;
    right: 0;
    top: 12px;
    width: 1px;
}

.table-define .cell-table-define:last-child:after {
    background-color: #fafafa;
    bottom: 12px;
    content: '';
    position: absolute;
    right: 0;
    top: 12px;
    width: 1px;
}

.table-define > tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    overflow-wrap: break-word;
    padding: 16px;
    transition: background 0.3s;
}

.label-light-danger,
.label-light-default,
.label-light-info,
.label-light-pending,
.label-light-success,
.label-light-warning {
    border-radius: 5px;
    font-size: 11px;
    height: -moz-max-content;
    height: max-content;
    line-height: 15px;
    padding: 7px 10px 5px;
    text-transform: uppercase;
    width: -moz-max-content;
    width: max-content;
}

.label-light-default {
    background-color: var(--default_color);
    color: #fff;
}

.label-light-pending {
    background-color: var(--pending_color);
    color: #000;
}

.label-light-info {
    background-color: var(--info_color);
    color: #fff;
}

.label-light-success {
    background-color: var(--success_color);
    color: #fff;
}

.label-light-danger {
    background-color: var(--error_color);
    color: #fff;
}

.label-light-warning {
    background-color: var(--warning_color);
    color: #fff;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.font-normal {
    font-weight: 400 !important;
}

.ant-tooltip-inner {
    font-size: 12px !important;
}

.pagination .ant-pagination-item-link {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ant-spin-nested-loading > div > .ant-spin {
    background-color: rgba(0, 0, 0, 0.1);
    max-height: 100% !important;
    z-index: 99999999999 !important;
}

.select-item-percent {
    background: var(--primary);
    border-radius: 100px;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    margin-left: 10px;
    padding: 2px 5px;
}

.row-summary-none-border-bt > td {
    border-bottom: none !important;
}

@media (max-width: 450px) {
    .summary-table-visible-mb {
        display: none !important;
    }
}

.editable-row-fly .ant-form-item-explain {
    font-size: 12px;
    position: absolute;
    top: 100%;
}

.row-plan-instance {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.item-plan-instance {
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    height: 100%;
    overflow: hidden;
    padding: 14px;
    position: relative;
}

.item-plan-instance:hover,
.item-plan-instance[data-active='true'] {
    border: 2px solid var(--primary);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
}

.card_item_image_shadow .title,
.item-plan-instance .title {
    color: #1e2736;
    font-size: 17px;
    width: 100%;
    word-break: break-word;
}

.card_item_image_shadow {
    align-items: flex-start;
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 100%;
}

.card_item_image_shadow:hover,
.card_item_image_shadow[data-active='true'] {
    border: 2px solid var(--primary);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
}

.input_select_link .ant-select-selector {
    padding: 0 !important;
}

.input_select_link .ant-select-arrow {
    color: #4d4d4d !important;
}

.input_select_link .input_select_link--popup_text {
    display: block;
    max-width: calc(100% - 34px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.input_select_link .ant-select-selector {
    border: none !important;
}

.input_select_link .ant-select-selector:focus {
    border: none !important;
}

.bg-card-gray {
    background-color: #f0f2f4;
}

.rounded-md {
    border-radius: var(--rounded-md) !important;
}

.CheckMarkDefault_box_check {
    color: var(--primary);
    position: absolute;
    right: -1px;
    top: 0;
}

.CheckMarkDefault_img_check {
    border-top-right-radius: var(--rounded-md);
    height: 35px;
    position: relative;
    width: 35px;
}

.CheckMarkDefault_icon_check {
    color: #fff !important;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 3px;
}

.border-hover-primary:hover,
.border-primary {
    border: 1px solid var(--primary) !important;
}

.min-width-350 {
    min-width: 350px !important;
}

.min-width-300 {
    min-width: 300px !important;
}

.min-width-250 {
    min-width: 250px !important;
}

.min-width-230 {
    min-width: 230px !important;
}

.min-width-200 {
    min-width: 200px !important;
}

.min-width-170 {
    min-width: 170px !important;
}

.min-width-150 {
    min-width: 150px !important;
}

.min-width-120 {
    min-width: 120px !important;
}

.min-width-100 {
    min-width: 100px !important;
}

.min-width-90 {
    min-width: 90px !important;
}

.min-width-80 {
    min-width: 80px !important;
}

.boxshadow-1 {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
}

.boxshadow-2 {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2) !important;
}

.boxshadow {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
}

.boxshadow-2-3 {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3) !important;
}

.boxshadow-hover-73:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
}

.boxshadow-none {
    box-shadow: none !important;
}

.choose-input-upload {
    background-color: #fff;
    border: 1px solid var(--primary) !important;
    border-radius: 8px;
    color: #000;
    cursor: pointer;
    font-size: 12px !important;
    padding: 1px 5px;
}

.choose-input-upload:hover {
    color: var(--primary) !important;
}

.default-table > table td,
.default-table > table th {
    border: 1px solid var(--border);
    padding: 10px;
    text-align: left;
    word-break: break-all;
}

.label-light-danger-lg,
.label-light-default-lg,
.label-light-info-lg,
.label-light-pending-lg,
.label-light-success-lg,
.label-light-warning-lg {
    border-radius: 3px;
    font-size: 13px;
    height: -moz-max-content;
    height: max-content;
    padding: 5px 10px;
    text-transform: uppercase;
    width: -moz-max-content;
    width: max-content;
}

.label-light-default-lg {
    background-color: var(--default_color);
    color: #fff;
}

.label-light-info-lg {
    background-color: var(--info_color);
    color: #fff;
}

.label-light-pending-lg {
    background-color: var(--pending_color);
    color: #000;
}

.label-light-success-lg {
    background-color: var(--success_color);
    color: #fff;
}

.label-light-danger-lg {
    background-color: var(--error_color);
    color: #fff;
}

.label-light-warning-lg {
    background-color: var(--warning_color);
    color: #fff;
}

.link-color > a,
.text-link {
    color: var(--link_color) !important;
}

.step-verify .ant-steps-item-title {
    font-size: 15px;
}

.step-verify .ant-steps-item-description {
    font-size: 13px;
}

.content-tickets img {
    height: auto;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.ant-comment-content-detail p {
    white-space: normal !important;
}

.input-number-hidden-arrow input::-webkit-inner-spin-button,
.input-number-hidden-arrow input::-webkit-outer-spin-button,
.input-number-hidden-arrow::-webkit-inner-spin-button,
.input-number-hidden-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

.break-all {
    word-break: break-all;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.btn-warning {
    background-color: var(--warning_color) !important;
    border-color: var(--warning_color) !important;
}

.ant-form-item-label > label {
    font-weight: 400 !important;
}

.container-upload-drag {
    position: relative;
}

.box-upload-drag {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    width: auto;
}

.container-upload-drag:hover .box-upload-drag {
    border: 1px dashed var(--primary);
}

.box-upload-drag .upload-drag-icon {
    color: var(--primary);
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 0;
    margin-top: 20px;
}

.box-upload-drag .upload-drag-title {
    color: #7a869a;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 5px;
}

.box-upload-drag .upload-drag-description {
    margin-top: 0;
}

.input-upload-drag {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.btn-upload-file {
    border: 1px solid var(--border);
    border-radius: 5px;
    cursor: pointer;
    height: 24px;
    line-height: 23px;
    max-width: -moz-max-content;
    max-width: max-content;
    padding: 5px 11px;
}

.btn-upload-file:hover {
    border: 1px solid var(--primary);
    color: var(--primary);
}

.input-new-folder {
    align-items: center;
    border: 2px dashed var(--border);
    border-radius: 5px;
    display: flex;
    padding: 5px 10px;
}

.input-new-folder:hover {
    border: 2px dashed var(--primary);
}

.box-share-fly {
    align-items: center;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 10px 15px;
}

.box-share-fly > span {
    word-break: break-all;
}

@media (max-width: 550px) {
    .mobile-width-full {
        width: 100% !important;
    }
}

.animation-shuttle {
    animation: animation-shuttle 2s linear infinite;
}

@keyframes animation-shuttle {
    0% {
        transform: scale(1);
    }

    10%,
    20% {
        transform: scale(0.9) rotate(-8deg);
    }

    30%,
    50%,
    70% {
        transform: scale(1.1) rotate(8deg);
    }

    40%,
    60% {
        transform: scale(1.1) rotate(-8deg);
    }

    80% {
        transform: scale(1) rotate(0);
    }
}

.operation-progress .ant-progress-bg {
    animation: in-progress 2.5s ease-in-out infinite;
}

@keyframes in-progress {
    0% {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(250%);
    }
}

.box-membership-name {
    border-radius: 20px;
    line-height: 13px;
    max-width: max-content;
    padding: 5px 10px;
    text-transform: uppercase;
    white-space: break-spaces;
}

.text-ellipsis {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    min-height: 40px;
    overflow: hidden;
}

.text-ellipsis.ellipsis-1 {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    min-height: 25px;
}

.text-ellipsis.ellipsis-3 {
    line-clamp: 3;
    -webkit-line-clamp: 3;
    min-height: 60px;
}

.text-ellipsis.ellipsis-4 {
    line-clamp: 4;
    -webkit-line-clamp: 4;
    min-height: 80px;
}

.text-ellipsis.ellipsis-5 {
    line-clamp: 5;
    -webkit-line-clamp: 5;
    min-height: 100px;
}

.text-ellipsis-1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.editable-row .ant-form-item-explain {
    font-size: 12px;
    position: absolute;
    top: 100%;
}

.box-center {
    justify-content: center !important;
}

.box-align-center,
.box-center {
    align-items: center !important;
    display: flex !important;
}

.box-justify-center {
    display: flex !important;
    justify-content: center !important;
}

.ant-btn .icon-tabler {
    height: 17px !important;
    width: 17px !important;
}

.ant-btn.ant-btn-sm .icon-tabler {
    height: 15px !important;
    width: 15px !important;
}

.ant-btn.ant-btn-lg .icon-tabler {
    height: 21px !important;
    width: 21px !important;
}

.ant-modal-header {
    margin-bottom: 20px !important;
}

.ant-modal-header .ant-modal-title {
    font-size: 20px !important;
}

.tabs-custom-fly .ant-tabs-tab .anticon {
    color: var(--sub_text);
}

.tabs-custom-fly .ant-tabs-tab.ant-tabs-tab-active .anticon,
.tabs-custom-fly .ant-tabs-tab:hover,
.tabs-custom-fly .ant-tabs-tab:hover .anticon {
    color: var(--primary);
}

.hover-underline:hover {
    text-decoration: underline !important;
}

.hover-bg-gray:hover {
    background-color: #f2f2f2 !important;
}

.button-filter-fly {
    align-items: center;
    background-color: #f2f2f2 !important;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    color: #42526e;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    gap: 8px;
    min-width: 80px !important;
    padding: 4px 11px;
}

.button-filter-fly:hover {
    background-color: var(--primary) !important;
    color: #fff;
}

.button-filter-fly .result-button-filter-fly {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: var(--primary) !important;
    border-radius: 10px;
    color: #fff !important;
    display: -webkit-box !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    max-width: 120px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
}

.button-filter-fly:hover .result-button-filter-fly {
    background-color: #fff !important;
    color: var(--primary) !important;
}

.button-filter-fly .ant-badge-count {
    background-color: var(--primary) !important;
    box-shadow: none;
    color: #fff !important;
}

.button-filter-fly:hover .ant-badge-count {
    background-color: #fff !important;
    color: var(--primary) !important;
}

.group-checkbox-filter-fly.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.group-checkbox-filter-fly.ant-checkbox-group .ant-checkbox-wrapper {
    margin-right: 0;
    margin-inline-start: 0 !important;
    padding: 7px 10px;
}

.group-checkbox-filter-fly.ant-checkbox-group .ant-checkbox-wrapper:hover {
    background-color: #f2f2f2;
}

.container-group-checkbox-filter-fly {
    background-color: #fff;
    max-height: 250px;
    max-width: 350px;
    min-width: 220px;
    overflow: auto;
    padding-bottom: 4px;
    padding-top: 4px;
}

.group-radio-filter-fly.ant-radio-group {
    display: flex;
    flex-direction: column;
}

.group-radio-filter-fly.ant-radio-group .ant-radio-wrapper {
    margin-right: 0;
    padding: 7px 10px;
}

.group-radio-filter-fly.ant-radio-group .ant-radio-wrapper:hover {
    background-color: #f2f2f2;
}

.checkbox-search {
    align-items: center;
    background-color: #f2f2f2 !important;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px !important;
    color: #42526e !important;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    gap: 8px;
    padding: 4px 11px !important;
}

.checkbox-search.checked {
    background-color: var(--primary) !important;
    color: #fff !important;
}

.input-number-hidden-arrow .ant-input-number-handler-wrap {
    display: none !important;
}

.input-number-hidden-arrow.text-center .ant-input-number-input {
    text-align: center !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selector {
    padding: 1px 4px;
}

.box-bandwidth-s3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-block: 5px;
    row-gap: 15px;
}

.box-bandwidth-s3 > div h3 {
    margin-bottom: 12px;
}

.box-bandwidth-s3 > div {
    position: relative;
    width: 20%;
}

.box-bandwidth-s3 > div:after {
    background-color: var(--border);
    bottom: 25%;
    content: '';
    position: absolute;
    right: 0;
    top: 25%;
    width: 1px;
}

.box-bandwidth-s3 > div:last-child:after {
    display: none;
}

@media (max-width: 1023px) {
    .box-bandwidth-s3 > div {
        width: 33.33%;
    }

    .box-bandwidth-s3 > div:nth-child(3):after {
        display: none;
    }
}

@media (max-width: 767px) {
    .box-bandwidth-s3 > div {
        width: 50%;
    }

    .box-bandwidth-s3 > div:nth-child(2n):after {
        display: none;
    }

    .box-bandwidth-s3 > div:nth-child(3):after {
        display: initial;
    }
}

@media (max-width: 500px) {
    .box-bandwidth-s3 {
        row-gap: 10px;
    }

    .box-bandwidth-s3 > div {
        padding-bottom: 15px;
        width: 100%;
    }

    .box-bandwidth-s3 > div:nth-child(2n):after {
        display: initial;
    }

    .box-bandwidth-s3 > div h3 {
        margin-bottom: 10px;
    }

    .box-bandwidth-s3 > div:last-child {
        padding-bottom: 0;
    }
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.z-index-5 {
    z-index: 5 !important;
}

.z-index-10 {
    z-index: 10 !important;
}

@media (max-width: 450px) {
    .box-search-action > div {
        min-width: calc(50% - 0.25rem);
        width: auto;
    }

    .box-search-action .input-search-filter {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .box-search-action > div {
        min-width: 100%;
        width: auto;
    }

    .box-search-action .input-search-filter {
        width: 100%;
    }
}

.btn-float-moon {
    background-color: #000;
}

.btn-float-moon .ant-float-btn-body {
    background-color: transparent !important;
}

.site-card-wrapper .row-billing {
    align-items: center;
    display: flex;
}

.site-card-wrapper .row-billing .col-calculation {
    display: flex;
    font-size: 30px;
    font-weight: 700;
    justify-content: center;
    width: 60px;
}

.site-card-wrapper .row-billing .ant-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
    width: calc(34% - 40px);
}

@media (max-width: 700px) {
    .site-card-wrapper .row-billing {
        align-items: start;
        flex-direction: column;
    }

    .site-card-wrapper .row-billing .ant-card,
    .site-card-wrapper .row-billing .col-calculation {
        min-height: auto;
        width: 100%;
    }
}

@media (max-width: 576px) {
    .xs-w-full {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .xsx-w-full {
        width: 100%;
    }
}

.animated-image-cart {
    max-width: 100px;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    z-index: 1001;
}

.animated-image-cart.in-cart {
    opacity: 0;
    transform: scale(0.5);
    transition: transform 0.2s ease-in-out;
}

#add-to-cart-btn,
#cart-icon {
    position: relative;
}

.tabs-header-no-border.ant-tabs-top .ant-tabs-nav:before {
    border-bottom: none;
}

.items-center {
    align-items: center;
}

.m-0 {
    margin: 0 !important;
}

.m-px {
    margin: 1px !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 0.75rem !important;
}

.m-4 {
    margin: 1rem !important;
}

.m-5 {
    margin: 1.25rem !important;
}

.m-6 {
    margin: 1.5rem !important;
}

.m-7 {
    margin: 1.75rem !important;
}

.m-8 {
    margin: 2rem !important;
}

.m-9 {
    margin: 2.25rem !important;
}

.m-10 {
    margin: 2.5rem !important;
}

.m-11 {
    margin: 2.75rem !important;
}

.m-12 {
    margin: 3rem !important;
}

.m-14 {
    margin: 3.5rem !important;
}

.m-16 {
    margin: 4rem !important;
}

.m-20 {
    margin: 5rem !important;
}

.m-24 {
    margin: 6rem !important;
}

.m-28 {
    margin: 7rem !important;
}

.m-32 {
    margin: 8rem !important;
}

.m-36 {
    margin: 9rem !important;
}

.m-40 {
    margin: 10rem !important;
}

.m-44 {
    margin: 11rem !important;
}

.m-48 {
    margin: 12rem !important;
}

.m-52 {
    margin: 13rem !important;
}

.m-56 {
    margin: 14rem !important;
}

.m-60 {
    margin: 15rem !important;
}

.m-64 {
    margin: 16rem !important;
}

.m-72 {
    margin: 18rem !important;
}

.m-80 {
    margin: 20rem !important;
}

.m-96 {
    margin: 24rem !important;
}

.m-auto {
    margin: auto;
}

.-m-0 {
    margin: 0;
}

.-m-px {
    margin: -1px;
}

.-m-1 {
    margin: -0.25rem !important;
}

.-m-2 {
    margin: -0.5rem !important;
}

.-m-3 {
    margin: -0.75rem !important;
}

.-m-4 {
    margin: -1rem !important;
}

.-m-5 {
    margin: -1.25rem !important;
}

.-m-6 {
    margin: -1.5rem !important;
}

.-m-7 {
    margin: -1.75rem !important;
}

.-m-8 {
    margin: -2rem !important;
}

.-m-9 {
    margin: -2.25rem !important;
}

.-m-10 {
    margin: -2.5rem !important;
}

.-m-11 {
    margin: -2.75rem !important;
}

.-m-12 {
    margin: -3rem !important;
}

.-m-14 {
    margin: -3.5rem !important;
}

.-m-16 {
    margin: -4rem !important;
}

.-m-20 {
    margin: -5rem !important;
}

.-m-24 {
    margin: -6rem !important;
}

.-m-28 {
    margin: -7rem !important;
}

.-m-32 {
    margin: -8rem !important;
}

.-m-36 {
    margin: -9rem !important;
}

.-m-40 {
    margin: -10rem !important;
}

.-m-44 {
    margin: -11rem !important;
}

.-m-48 {
    margin: -12rem !important;
}

.-m-52 {
    margin: -13rem !important;
}

.-m-56 {
    margin: -14rem !important;
}

.-m-60 {
    margin: -15rem !important;
}

.-m-64 {
    margin: -16rem !important;
}

.-m-72 {
    margin: -18rem !important;
}

.-m-80 {
    margin: -20rem !important;
}

.-m-96 {
    margin: -24rem !important;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.mx-px {
    margin-left: 1px;
    margin-right: 1px;
}

.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
}

.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
}

.mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.mx-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
}

.mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.mx-9 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
}

.mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
}

.mx-11 {
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
}

.mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
}

.mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
}

.mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}

.mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}

.mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
}

.mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
}

.mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
}

.mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
}

.mx-44 {
    margin-left: 11rem !important;
    margin-right: 11rem !important;
}

.mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
}

.mx-52 {
    margin-left: 13rem !important;
    margin-right: 13rem !important;
}

.mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
}

.mx-60 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
}

.mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
}

.mx-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
}

.mx-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
}

.mx-96 {
    margin-left: 24rem !important;
    margin-right: 24rem !important;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.-mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.-mx-px {
    margin-left: -1px;
    margin-right: -1px;
}

.-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
}

.-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
}

.-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
}

.-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
}

.-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
}

.-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.-mx-7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
}

.-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
}

.-mx-9 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
}

.-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
}

.-mx-11 {
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
}

.-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
}

.-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
}

.-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
}

.-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
}

.-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
}

.-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
}

.-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
}

.-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
}

.-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
}

.-mx-44 {
    margin-left: -11rem !important;
    margin-right: -11rem !important;
}

.-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
}

.-mx-52 {
    margin-left: -13rem !important;
    margin-right: -13rem !important;
}

.-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
}

.-mx-60 {
    margin-left: -15rem !important;
    margin-right: -15rem !important;
}

.-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
}

.-mx-72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
}

.-mx-80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
}

.-mx-96 {
    margin-left: -24rem !important;
    margin-right: -24rem !important;
}

.my-0 {
    margin-bottom: 0;
    margin-top: 0;
}

.my-px {
    margin-bottom: 1px;
    margin-top: 1px;
}

.my-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
}

.my-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
}

.my-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
}

.my-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.my-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
}

.my-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
}

.my-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
}

.my-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
}

.my-9 {
    margin-bottom: 2.25rem !important;
    margin-top: 2.25rem !important;
}

.my-10 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
}

.my-11 {
    margin-bottom: 2.75rem !important;
    margin-top: 2.75rem !important;
}

.my-12 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
}

.my-14 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
}

.my-16 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
}

.my-20 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
}

.-my-24 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
}

.my-28 {
    margin-bottom: 7rem !important;
    margin-top: 7rem !important;
}

.my-32 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
}

.my-36 {
    margin-bottom: 9rem !important;
    margin-top: 9rem !important;
}

.my-40 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
}

.my-44 {
    margin-bottom: 11rem !important;
    margin-top: 11rem !important;
}

.my-48 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
}

.my-52 {
    margin-bottom: 13rem !important;
    margin-top: 13rem !important;
}

.my-56 {
    margin-bottom: 14rem !important;
    margin-top: 14rem !important;
}

.my-60 {
    margin-bottom: 15rem !important;
    margin-top: 15rem !important;
}

.my-64 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important;
}

.my-72 {
    margin-bottom: 18rem !important;
    margin-top: 18rem !important;
}

.my-80 {
    margin-bottom: 20rem !important;
    margin-top: 20rem !important;
}

.my-96 {
    margin-bottom: 24rem !important;
    margin-top: 24rem !important;
}

.my-auto {
    margin-bottom: auto;
    margin-top: auto;
}

.-my-0 {
    margin-bottom: 0;
    margin-top: 0;
}

.-my-px {
    margin-bottom: -1px;
    margin-top: -1px;
}

.-my-1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
}

.-my-2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
}

.-my-3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
}

.-my-4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
}

.-my-5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
}

.-my-6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
}

.-my-7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
}

.-my-8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
}

.-my-9 {
    margin-bottom: -2.25rem !important;
    margin-top: -2.25rem !important;
}

.-my-10 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
}

.-my-11 {
    margin-bottom: -2.75rem !important;
    margin-top: -2.75rem !important;
}

.-my-12 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
}

.-my-14 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
}

.-my-16 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
}

.-my-20 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
}

.-my-24 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
}

.-my-28 {
    margin-bottom: -7rem !important;
    margin-top: -7rem !important;
}

.-my-32 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
}

.-my-36 {
    margin-bottom: -9rem !important;
    margin-top: -9rem !important;
}

.-my-40 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
}

.-my-44 {
    margin-bottom: -11rem !important;
    margin-top: -11rem !important;
}

.-my-48 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
}

.-my-52 {
    margin-bottom: -13rem !important;
    margin-top: -13rem !important;
}

.-my-56 {
    margin-bottom: -14rem !important;
    margin-top: -14rem !important;
}

.-my-60 {
    margin-bottom: -15rem !important;
    margin-top: -15rem !important;
}

.-my-64 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important;
}

.-my-72 {
    margin-bottom: -18rem !important;
    margin-top: -18rem !important;
}

.-my-80 {
    margin-bottom: -20rem !important;
    margin-top: -20rem !important;
}

.-my-96 {
    margin-bottom: -24rem !important;
    margin-top: -24rem !important;
}

.mt-0 {
    margin-top: 0;
}

.mt-px {
    margin-top: 1px;
}

.mt-2px {
    margin-top: 2px;
}

.mt-3px {
    margin-top: 3px;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 0.75rem !important;
}

.mt-4 {
    margin-top: 1rem !important;
}

.mt-5 {
    margin-top: 1.25rem !important;
}

.mt-6 {
    margin-top: 1.5rem !important;
}

.mt-7 {
    margin-top: 1.75rem !important;
}

.mt-8 {
    margin-top: 2rem !important;
}

.mt-9 {
    margin-top: 2.25rem !important;
}

.mt-10 {
    margin-top: 2.5rem !important;
}

.mt-11 {
    margin-top: 2.75rem !important;
}

.mt-12 {
    margin-top: 3rem !important;
}

.mt-14 {
    margin-top: 3.5rem !important;
}

.mt-16 {
    margin-top: 4rem !important;
}

.mt-20 {
    margin-top: 5rem !important;
}

.mt-24 {
    margin-top: 6rem !important;
}

.mt-28 {
    margin-top: 7rem !important;
}

.mt-32 {
    margin-top: 8rem !important;
}

.mt-36 {
    margin-top: 9rem !important;
}

.mt-40 {
    margin-top: 10rem !important;
}

.mt-44 {
    margin-top: 11rem !important;
}

.mt-48 {
    margin-top: 12rem !important;
}

.mt-52 {
    margin-top: 13rem !important;
}

.mt-56 {
    margin-top: 14rem !important;
}

.mt-60 {
    margin-top: 15rem !important;
}

.mt-64 {
    margin-top: 16rem !important;
}

.mt-72 {
    margin-top: 18rem !important;
}

.mt-80 {
    margin-top: 20rem !important;
}

.mt-96 {
    margin-top: 24rem !important;
}

.mt-auto {
    margin-top: auto;
}

.-mt-0 {
    margin-top: 0;
}

.-mt-px {
    margin-top: -1px;
}

.-mt-1 {
    margin-top: -0.25rem !important;
}

.-mt-2 {
    margin-top: -0.5rem !important;
}

.-mt-3 {
    margin-top: -0.75rem !important;
}

.-mt-4 {
    margin-top: -1rem !important;
}

.-mt-5 {
    margin-top: -1.25rem !important;
}

.-mt-6 {
    margin-top: -1.5rem !important;
}

.-mt-7 {
    margin-top: -1.75rem !important;
}

.-mt-8 {
    margin-top: -2rem !important;
}

.-mt-9 {
    margin-top: -2.25rem !important;
}

.-mt-10 {
    margin-top: -2.5rem !important;
}

.-mt-11 {
    margin-top: -2.75rem !important;
}

.-mt-12 {
    margin-top: -3rem !important;
}

.-mt-14 {
    margin-top: -3.5rem !important;
}

.-mt-16 {
    margin-top: -4rem !important;
}

.-mt-20 {
    margin-top: -5rem !important;
}

.-mt-24 {
    margin-top: -6rem !important;
}

.-mt-28 {
    margin-top: -7rem !important;
}

.-mt-32 {
    margin-top: -8rem !important;
}

.-mt-36 {
    margin-top: -9rem !important;
}

.-mt-40 {
    margin-top: -10rem !important;
}

.-mt-44 {
    margin-top: -11rem !important;
}

.-mt-48 {
    margin-top: -12rem !important;
}

.-mt-52 {
    margin-top: -13rem !important;
}

.-mt-56 {
    margin-top: -14rem !important;
}

.-mt-60 {
    margin-top: -15rem !important;
}

.-mt-64 {
    margin-top: -16rem !important;
}

.-mt-72 {
    margin-top: -18rem !important;
}

.-mt-80 {
    margin-top: -20rem !important;
}

.-mt-96 {
    margin-top: -24rem !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-px {
    margin-right: 1px !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 0.75rem !important;
}

.mr-4 {
    margin-right: 1rem !important;
}

.mr-5 {
    margin-right: 1.25rem !important;
}

.mr-6 {
    margin-right: 1.5rem !important;
}

.mr-7 {
    margin-right: 1.75rem !important;
}

.mr-8 {
    margin-right: 2rem !important;
}

.mr-9 {
    margin-right: 2.25rem !important;
}

.mr-10 {
    margin-right: 2.5rem !important;
}

.mr-11 {
    margin-right: 2.75rem !important;
}

.mr-12 {
    margin-right: 3rem !important;
}

.mr-14 {
    margin-right: 3.5rem !important;
}

.mr-16 {
    margin-right: 4rem !important;
}

.mr-20 {
    margin-right: 5rem !important;
}

.mr-24 {
    margin-right: 6rem !important;
}

.mr-28 {
    margin-right: 7rem !important;
}

.mr-32 {
    margin-right: 8rem !important;
}

.mr-36 {
    margin-right: 9rem !important;
}

.mr-40 {
    margin-right: 10rem !important;
}

.mr-44 {
    margin-right: 11rem !important;
}

.mr-48 {
    margin-right: 12rem !important;
}

.mr-52 {
    margin-right: 13rem !important;
}

.mr-56 {
    margin-right: 14rem !important;
}

.mr-60 {
    margin-right: 15rem !important;
}

.mr-64 {
    margin-right: 16rem !important;
}

.mr-72 {
    margin-right: 18rem !important;
}

.mr-80 {
    margin-right: 20rem !important;
}

.mr-96 {
    margin-right: 24rem !important;
}

.mr-auto {
    margin-right: auto;
}

.-mr-0 {
    margin-right: 0;
}

.-mr-px {
    margin-right: -1px;
}

.-mr-1 {
    margin-right: -0.25rem !important;
}

.-mr-2 {
    margin-right: -0.5rem !important;
}

.-mr-3 {
    margin-right: -0.75rem !important;
}

.-mr-4 {
    margin-right: -1rem !important;
}

.-mr-5 {
    margin-right: -1.25rem !important;
}

.-mr-6 {
    margin-right: -1.5rem !important;
}

.-mr-7 {
    margin-right: -1.75rem !important;
}

.-mr-8 {
    margin-right: -2rem !important;
}

.-mr-9 {
    margin-right: -2.25rem !important;
}

.-mr-10 {
    margin-right: -2.5rem !important;
}

.-mr-11 {
    margin-right: -2.75rem !important;
}

.-mr-12 {
    margin-right: -3rem !important;
}

.-mr-14 {
    margin-right: -3.5rem !important;
}

.-mr-16 {
    margin-right: -4rem !important;
}

.-mr-20 {
    margin-right: -5rem !important;
}

.-mr-24 {
    margin-right: -6rem !important;
}

.-mr-28 {
    margin-right: -7rem !important;
}

.-mr-32 {
    margin-right: -8rem !important;
}

.-mr-36 {
    margin-right: -9rem !important;
}

.-mr-40 {
    margin-right: -10rem !important;
}

.-mr-44 {
    margin-right: -11rem !important;
}

.-mr-48 {
    margin-right: -12rem !important;
}

.-mr-52 {
    margin-right: -13rem !important;
}

.-mr-56 {
    margin-right: -14rem !important;
}

.-mr-60 {
    margin-right: -15rem !important;
}

.-mr-64 {
    margin-right: -16rem !important;
}

.-mr-72 {
    margin-right: -18rem !important;
}

.-mr-80 {
    margin-right: -20rem !important;
}

.-mr-96 {
    margin-right: -24rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-px {
    margin-bottom: 1px;
}

.mb-2px {
    margin-bottom: 2px !important;
}

.mb-3px {
    margin-bottom: 3px !important;
}

.mb-4px {
    margin-bottom: 4px !important;
}

.mb-5px {
    margin-bottom: 5px !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 0.75rem !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

.mb-5 {
    margin-bottom: 1.25rem !important;
}

.mb-6 {
    margin-bottom: 1.5rem !important;
}

.mb-7 {
    margin-bottom: 1.75rem !important;
}

.mb-8 {
    margin-bottom: 2rem !important;
}

.mb-9 {
    margin-bottom: 2.25rem !important;
}

.mb-10 {
    margin-bottom: 2.5rem !important;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.mb-11 {
    margin-bottom: 2.75rem !important;
}

.mb-12 {
    margin-bottom: 3rem !important;
}

.mb-14 {
    margin-bottom: 3.5rem !important;
}

.mb-16 {
    margin-bottom: 4rem !important;
}

.mb-18 {
    margin-bottom: 4.5rem !important;
}

.mb-20 {
    margin-bottom: 5rem !important;
}

.mb-24 {
    margin-bottom: 6rem !important;
}

.mb-28 {
    margin-bottom: 7rem !important;
}

.mb-32 {
    margin-bottom: 8rem !important;
}

.mb-36 {
    margin-bottom: 9rem !important;
}

.mb-40 {
    margin-bottom: 10rem !important;
}

.mb-44 {
    margin-bottom: 11rem !important;
}

.mb-48 {
    margin-bottom: 12rem !important;
}

.mb-52 {
    margin-bottom: 13rem !important;
}

.mb-56 {
    margin-bottom: 14rem !important;
}

.mb-60 {
    margin-bottom: 15rem !important;
}

.mb-64 {
    margin-bottom: 16rem !important;
}

.mb-72 {
    margin-bottom: 18rem !important;
}

.mb-80 {
    margin-bottom: 20rem !important;
}

.mb-96 {
    margin-bottom: 24rem !important;
}

.mb-auto {
    margin-bottom: auto;
}

.-mb-0 {
    margin-bottom: 0;
}

.-mb-px {
    margin-bottom: -1px;
}

.-mb-1 {
    margin-bottom: -0.25rem !important;
}

.-mb-2 {
    margin-bottom: -0.5rem !important;
}

.-mb-3 {
    margin-bottom: -0.75rem !important;
}

.-mb-4 {
    margin-bottom: -1rem !important;
}

.-mb-5 {
    margin-bottom: -1.25rem !important;
}

.-mb-6 {
    margin-bottom: -1.5rem !important;
}

.-mb-7 {
    margin-bottom: -1.75rem !important;
}

.-mb-8 {
    margin-bottom: -2rem !important;
}

.-mb-9 {
    margin-bottom: -2.25rem !important;
}

.-mb-10 {
    margin-bottom: -2.5rem !important;
}

.-mb-11 {
    margin-bottom: -2.75rem !important;
}

.-mb-12 {
    margin-bottom: -3rem !important;
}

.-mb-14 {
    margin-bottom: -3.5rem !important;
}

.-mb-16 {
    margin-bottom: -4rem !important;
}

.-mb-20 {
    margin-bottom: -5rem !important;
}

.-mb-24 {
    margin-bottom: -6rem !important;
}

.-mb-28 {
    margin-bottom: -7rem !important;
}

.-mb-32 {
    margin-bottom: -8rem !important;
}

.-mb-36 {
    margin-bottom: -9rem !important;
}

.-mb-40 {
    margin-bottom: -10rem !important;
}

.-mb-44 {
    margin-bottom: -11rem !important;
}

.-mb-48 {
    margin-bottom: -12rem !important;
}

.-mb-52 {
    margin-bottom: -13rem !important;
}

.-mb-56 {
    margin-bottom: -14rem !important;
}

.-mb-60 {
    margin-bottom: -15rem !important;
}

.-mb-64 {
    margin-bottom: -16rem !important;
}

.-mb-72 {
    margin-bottom: -18rem !important;
}

.-mb-80 {
    margin-bottom: -20rem !important;
}

.-mb-96 {
    margin-bottom: -24rem !important;
}

.ml-0 {
    margin-left: 0;
}

.ml-px {
    margin-left: 1px;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 0.75rem !important;
}

.ml-4 {
    margin-left: 1rem !important;
}

.ml-5 {
    margin-left: 1.25rem !important;
}

.ml-6 {
    margin-left: 1.5rem !important;
}

.ml-7 {
    margin-left: 1.75rem !important;
}

.ml-8 {
    margin-left: 2rem !important;
}

.ml-9 {
    margin-left: 2.25rem !important;
}

.ml-10 {
    margin-left: 2.5rem !important;
}

.ml-11 {
    margin-left: 2.75rem !important;
}

.ml-12 {
    margin-left: 3rem !important;
}

.ml-14 {
    margin-left: 3.5rem !important;
}

.ml-16 {
    margin-left: 4rem !important;
}

.ml-20 {
    margin-left: 5rem !important;
}

.ml-24 {
    margin-left: 6rem !important;
}

.ml-28 {
    margin-left: 7rem !important;
}

.ml-32 {
    margin-left: 8rem !important;
}

.ml-36 {
    margin-left: 9rem !important;
}

.ml-40 {
    margin-left: 10rem !important;
}

.ml-44 {
    margin-left: 11rem !important;
}

.ml-48 {
    margin-left: 12rem !important;
}

.ml-52 {
    margin-left: 13rem !important;
}

.ml-56 {
    margin-left: 14rem !important;
}

.ml-60 {
    margin-left: 15rem !important;
}

.ml-64 {
    margin-left: 16rem !important;
}

.ml-72 {
    margin-left: 18rem !important;
}

.ml-80 {
    margin-left: 20rem !important;
}

.ml-96 {
    margin-left: 24rem !important;
}

.ml-auto {
    margin-left: auto;
}

.-ml-0 {
    margin-left: 0;
}

.-ml-px {
    margin-left: -1px;
}

.-ml-1 {
    margin-left: -0.25rem !important;
}

.-ml-2 {
    margin-left: -0.5rem !important;
}

.-ml-3 {
    margin-left: -0.75rem !important;
}

.-ml-4 {
    margin-left: -1rem !important;
}

.-ml-5 {
    margin-left: -1.25rem !important;
}

.-ml-6 {
    margin-left: -1.5rem !important;
}

.-ml-7 {
    margin-left: -1.75rem !important;
}

.-ml-8 {
    margin-left: -2rem !important;
}

.-ml-9 {
    margin-left: -2.25rem !important;
}

.-ml-10 {
    margin-left: -2.5rem !important;
}

.-ml-11 {
    margin-left: -2.75rem !important;
}

.-ml-12 {
    margin-left: -3rem !important;
}

.-ml-14 {
    margin-left: -3.5rem !important;
}

.-ml-16 {
    margin-left: -4rem !important;
}

.-ml-20 {
    margin-left: -5rem !important;
}

.-ml-24 {
    margin-left: -6rem !important;
}

.-ml-28 {
    margin-left: -7rem !important;
}

.-ml-32 {
    margin-left: -8rem !important;
}

.-ml-36 {
    margin-left: -9rem !important;
}

.-ml-40 {
    margin-left: -10rem !important;
}

.-ml-44 {
    margin-left: -11rem !important;
}

.-ml-48 {
    margin-left: -12rem !important;
}

.-ml-52 {
    margin-left: -13rem !important;
}

.-ml-56 {
    margin-left: -14rem !important;
}

.-ml-60 {
    margin-left: -15rem !important;
}

.-ml-64 {
    margin-left: -16rem !important;
}

.-ml-72 {
    margin-left: -18rem !important;
}

.-ml-80 {
    margin-left: -20rem !important;
}

.-ml-96 {
    margin-left: -24rem !important;
}

.text-sm {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
}

.text-small {
    font-size: small;
}

.text-smaller {
    font-size: smaller;
}

.font-max {
    font-weight: 800;
}

.font-bold {
    font-weight: 700;
}

.font-semibold {
    font-weight: 600;
}

.font-medium {
    font-weight: 500;
}

.text-primary {
    color: var(--primary) !important;
}

.text-1e2 {
    color: #1e2736 !important;
}

.text-opacity-primary {
    background-color: var(--primary);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center !important;
}

.text-start {
    text-align: start !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify;
}

.text-line-through {
    text-decoration: line-through;
}

.text-info {
    color: var(--color-main) !important;
}

.text-success {
    color: var(--success_color) !important;
}

.text-warning {
    color: var(--warning_color) !important;
}

.text-danger {
    color: var(--error_color);
}

.text-error {
    color: red;
    font-size: 13px;
}

.p-8px {
    padding: 8px !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-right: 0 !important;
}

.py-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-1px {
    padding-top: 1px !important;
}

.pt-2px {
    padding-top: 2px !important;
}

.pt-3px {
    padding-top: 3px !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pb-3px {
    padding-bottom: 3px !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.px-1 {
    padding-right: 0.25rem !important;
}

.px-1-5 {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
}

.py-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-2-5 {
    padding: 0.75rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-2-5 {
    padding-top: 0.75rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-2-5 {
    padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
}

.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.py-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
}

.py-2-5 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.px-3 {
    padding-right: 1rem !important;
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
}

.py-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.px-5 {
    padding-right: 3rem !important;
}

.py-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}

.py-10 {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}

.py-20 {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

.py-25 {
    padding-bottom: 25px !important;
    padding-top: 25px !important;
}

.py-30 {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
}

.py-35 {
    padding-bottom: 35px !important;
    padding-top: 35px !important;
}

.w-20 {
    width: 5rem !important;
}

.w-40 {
    width: 10rem !important;
}

.w-60 {
    width: 15rem !important;
}

.w-auto {
    width: auto !important;
}

.w-full {
    width: 100% !important;
}

.h-full {
    height: 100% !important;
}

.w-min {
    width: -moz-min-content !important;
    width: min-content !important;
}

.w-max-full {
    width: 100% !important;
}

.w-max {
    width: -moz-max-content !important;
    width: max-content !important;
}

.w-max-35 {
    max-width: 35px !important;
}

.w-max-40 {
    max-width: 40px !important;
}

.w-max-45 {
    max-width: 45px !important;
}

.w-max-50 {
    max-width: 50px !important;
}

.w-max-80 {
    max-width: 80px !important;
}

.w-max-100 {
    max-width: 100px !important;
}

.w-max-120 {
    max-width: 120px !important;
}

.w-max-130 {
    max-width: 130px !important;
}

.w-max-140 {
    max-width: 140px !important;
}

.w-max-150 {
    max-width: 150px !important;
}

.w-max-200 {
    max-width: 200px !important;
}

.w-max-250 {
    max-width: 250px !important;
}

.w-max-270 {
    max-width: 270px !important;
}

.w-max-300 {
    max-width: 300px !important;
}

.w-max-320 {
    max-width: 320px !important;
}

.w-max-350 {
    max-width: 350px !important;
}

.w-max-400 {
    max-width: 400px !important;
}

.w-max-450 {
    max-width: 450px !important;
}

.w-max-500 {
    max-width: 500px !important;
}

.w-max-550 {
    max-width: 550px !important;
}

.w-max-600 {
    max-width: 600px !important;
}

.w-max-650 {
    max-width: 650px !important;
}

.w-max-700 {
    max-width: 700px !important;
}

.w-max-750 {
    max-width: 750px !important;
}

.w-max-800 {
    max-width: 800px !important;
}

.w-max-850 {
    max-width: 850px !important;
}

.w-max-900 {
    max-width: 900px !important;
}

.w-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
}

.h-auto {
    height: auto !important;
}

.min-h-auto {
    min-height: auto !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-1-5 {
    gap: 0.375rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 0.75rem !important;
}

.gap-4 {
    gap: 1rem !important;
}

.gap-5 {
    gap: 1.25rem !important;
}

.gap-6 {
    gap: 1.5rem !important;
}

.row-gap-1 {
    row-gap: 0.25rem !important;
}

.row-gap-2 {
    row-gap: 0.5rem !important;
}

.row-gap-3 {
    row-gap: 0.75rem !important;
}

.row-gap-4 {
    row-gap: 1rem !important;
}

.row-gap-5 {
    row-gap: 1.25rem !important;
}

.row-gap-6 {
    row-gap: 1.5rem !important;
}

.col-gap-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
}

.col-gap-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
}

.col-gap-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
}

.col-gap-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
}

.col-gap-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
}

.col-gap-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
}

.hidden {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.flex-first {
    order: -1;
}

.flex-last {
    order: 1;
}

.flex-unordered {
    order: 0;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.border {
    border: 1px solid #f0f0f0 !important;
}

.border-color {
    border-color: #f0f0f0 !important;
}

.border-antd {
    border: 1px solid var(--border-antd) !important;
}

.border-bottom {
    border-bottom: 1px solid #f0f0f0 !important;
}

.border-top-white {
    border-top: 1px solid #fff;
}

.border-bottom-white {
    border-bottom: 1px solid #fff;
}

.border-bottom-black {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.border-red {
    border-color: #f44336 !important;
}

.border-top {
    border-top: 1px solid #f0f0f0;
}

.border-right {
    border-right: 1px solid #f0f0f0;
}

.border-left {
    border-left: 1px solid #f0f0f0;
}

.border-gray {
    border-color: #e5e7eb !important;
}

.border-none {
    border: none !important;
}

.border-bottom-none {
    border-bottom: none !important;
}

.border-top-none {
    border-top: none !important;
}

.border-left-none {
    border-left: none !important;
}

.border-right-none {
    border-right: none !important;
}

.border-success {
    border: 1px solid var(--success_color) !important;
}

.border-warning {
    border: 1px solid var(--warning_color) !important;
}

.border-info {
    border: 1px solid var(--info_color) !important;
}

.border-warning[disabled] {
    border: 1px solid var(--border-antd) !important;
}

.border-color-antd {
    border-color: var(--border-antd) !important;
}

.border-1 {
    border-width: 1px !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-3 {
    border-width: 3px !important;
}

.border-4 {
    border-width: 4px !important;
}

.border-5 {
    border-width: 5px !important;
}

.border-ddd {
    border-color: #ddd !important;
}

.border-white {
    border-color: #fff !important;
}

.border-t-dashed {
    border-top-style: dashed !important;
}

.border-b-dashed {
    border-bottom-style: dashed !important;
}

.border-l-dashed {
    border-left-style: dashed !important;
}

.border-r-dashed {
    border-right-style: dashed !important;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-21 {
    font-size: 21px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-23 {
    font-size: 23px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-25 {
    font-size: 25px !important;
}

.font-size-26 {
    font-size: 26px !important;
}

.font-size-27 {
    font-size: 27px !important;
}

.font-size-28 {
    font-size: 28px !important;
}

.font-size-29 {
    font-size: 29px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-31 {
    font-size: 31px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-33 {
    font-size: 33px !important;
}

.font-size-34 {
    font-size: 34px !important;
}

.font-size-35 {
    font-size: 35px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-45 {
    font-size: 45px !important;
}

.font-size-50 {
    font-size: 50px !important;
}

.line-height-11 {
    line-height: 11px !important;
}

.line-height-12 {
    line-height: 12px !important;
}

.line-height-13 {
    line-height: 13px !important;
}

.line-height-14 {
    line-height: 14px !important;
}

.line-height-15 {
    line-height: 15px !important;
}

.line-height-16 {
    line-height: 16px !important;
}

.line-height-17 {
    line-height: 17px !important;
}

.line-height-18 {
    line-height: 18px !important;
}

.line-height-19 {
    line-height: 19px !important;
}

.line-height-20 {
    line-height: 20px !important;
}

.line-height-21 {
    line-height: 21px !important;
}

.line-height-22 {
    line-height: 22px !important;
}

.line-height-23 {
    line-height: 23px !important;
}

.line-height-24 {
    line-height: 24px !important;
}

.line-height-25 {
    line-height: 25px !important;
}

.line-height-26 {
    line-height: 26px !important;
}

.line-height-27 {
    line-height: 27px !important;
}

.line-height-28 {
    line-height: 28px !important;
}

.line-height-29 {
    line-height: 29px !important;
}

.line-height-30 {
    line-height: 30px !important;
}

.line-height-31 {
    line-height: 31px !important;
}

.line-height-32 {
    line-height: 32px !important;
}

.line-height-33 {
    line-height: 33px !important;
}

.line-height-34 {
    line-height: 34px !important;
}

.line-height-35 {
    line-height: 35px !important;
}

.line-height-36 {
    line-height: 36px !important;
}

.line-height-37 {
    line-height: 37px !important;
}

.line-height-38 {
    line-height: 38px !important;
}

.line-height-39 {
    line-height: 39px !important;
}

.line-height-40 {
    line-height: 40px !important;
}

.background-primary {
    background-color: var(--primary) !important;
}

.background-transparent {
    background-color: transparent !important;
}

.background-gray {
    background-color: #f2f2f2 !important;
}

.background-black {
    background-color: #000 !important;
}

.background-black-1 {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.background-black-2 {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.background-black-3 {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.background-black-4 {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.background-black-5 {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-black1 {
    color: rgba(0, 0, 0, 0.1) !important;
}

.text-black2 {
    color: rgba(0, 0, 0, 0.2) !important;
}

.text-black3 {
    color: rgba(0, 0, 0, 0.3) !important;
}

.text-black4 {
    color: rgba(0, 0, 0, 0.4) !important;
}

.text-black5 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-black6 {
    color: rgba(0, 0, 0, 0.6) !important;
}

.text-black7 {
    color: rgba(0, 0, 0, 0.7) !important;
}

.text-black8 {
    color: rgba(0, 0, 0, 0.8) !important;
}

.text-black9 {
    color: rgba(0, 0, 0, 0.9) !important;
}

.text-fly {
    color: #031b4e;
}

.text-67 {
    color: #676767 !important;
}

.text-default {
    color: rgba(0, 0, 0, 0.85);
}

.max-w-content {
    max-width: -moz-max-content !important;
    max-width: max-content !important;
}

.max-h-content {
    max-height: -moz-max-content !important;
    max-height: max-content !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-8 {
    border-radius: 8px !important;
}

.rounded-6 {
    border-radius: 6px !important;
}

.rounded-3 {
    border-radius: 3px !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
}

.rounded-right,
.rounded-top {
    border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-full {
    border-radius: 1000px !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-top-3 {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-bottom-3 {
    border-bottom-right-radius: 3px !important;
}

.rounded-bottom-3,
.rounded-left-3 {
    border-bottom-left-radius: 3px !important;
}

.rounded-left-3 {
    border-top-left-radius: 3px !important;
}

.rounded-right-3 {
    border-bottom-right-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
}

.rounded-right-0,
.rounded-top-0 {
    border-top-right-radius: 0 !important;
}

.rounded-bottom-0,
.rounded-right-0 {
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0,
.rounded-left-0 {
    border-bottom-left-radius: 0 !important;
}

.rounded-left-0 {
    border-top-left-radius: 0 !important;
}

.min-height-30 {
    min-height: 30px !important;
}

.min-height-32 {
    min-height: 32px !important;
}

.min-height-35 {
    min-height: 35px !important;
}

.min-height-40 {
    min-height: 40px !important;
}

.min-height-45 {
    min-height: 45px !important;
}

.min-height-50 {
    min-height: 50px !important;
}

.min-height-100 {
    min-height: 100px !important;
}

.min-height-150 {
    min-height: 150px !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
        width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 100%;
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 100%;
        width: 1140px;
    }
}

@media (min-width: 991px) {
    [class*='max-width-'] {
        margin: 0 auto;
    }

    .max-width-50 {
        max-width: 50%;
    }

    .max-width-55 {
        max-width: 55%;
    }

    .max-width-60 {
        max-width: 60%;
    }

    .max-width-70 {
        max-width: 70%;
    }

    .max-width-80 {
        max-width: 80%;
    }

    .max-width-90 {
        max-width: 90%;
    }

    .max-width-85 {
        max-width: 85%;
    }
}

.text-green {
    color: #1fba5c !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-italic {
    font-style: italic;
}

.text-normal {
    font-style: normal !important;
}

.text-red {
    color: #f44336 !important;
}

.text-subtitle {
    color: var(--sub_text) !important;
}

.text-current {
    color: currentColor !important;
}

.hover-blue:hover {
    color: var(--primary) !important;
}

.hover-red:hover {
    color: var(--error_color) !important;
}

.hover-border-primary:hover {
    border-color: var(--primary) !important;
}

.hover-border-red:hover {
    border-color: var(--error_color) !important;
}

.width-max-content {
    max-width: -moz-max-content;
    max-width: max-content;
}

.white-space-line {
    white-space: pre-line;
}

.white-space-break {
    white-space: break-spaces !important;
}

.word-break-all {
    word-break: break-all !important;
}

.background-white {
    background-color: #fff !important;
}

.background-hover-black02:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.background-blue-05 {
    background-color: rgba(54, 153, 255, 0.05) !important;
}

.background-header-table {
    background-color: #fafafa !important;
}

.background-success {
    background-color: #4caf50 !important;
    color: #fff !important;
}

.background-red {
    background-color: red !important;
    color: #fff !important;
}

.background-warning {
    background-color: var(--warning_color) !important;
    color: #fff !important;
}

.rounded-10 {
    border-radius: 10px !important;
}

.rounded-15 {
    border-radius: 15px !important;
}

.rounded-20 {
    border-radius: 20px !important;
}

.rounded-30 {
    border-radius: 30px !important;
}

.flex-1 {
    flex: 1;
}

.radio-group-fly {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center !important;
}

.box-shadown-full {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}

.box-shadow-full-small {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.box-shadown {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 12px rgba(0, 0, 0, 0.2);
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.input-search-filter {
    max-width: 250px;
    min-width: 170px;
    width: 100%;
}

@media (max-width: 500px) {
    .input-search-filter {
        max-width: 100%;
        min-width: 170px;
        width: 100%;
    }
}

.no-select {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
}

body {
    margin: 0 !important;
}

a {
    text-decoration: none !important;
}

h2,
h3,
h4,
p {
    margin-top: 0;
}

a,
b,
div,
h1,
h2,
h3,
h4,
li,
p,
span,
input,
ul {
    font-family: Mulish, sans-serif !important;
}

.link-active {
    color: #b1520f !important;
}

.ant-segmented-item {
    margin-bottom: 0 !important;
}

a,
area,
button,
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

.rotating-svg {
    animation: rotate 2s linear infinite;
    transform-box: fill-box;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

.loader {
    align-items: center;
    animation: spin 2s linear infinite;
    border-bottom: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #f3f3f3;
    border-color: blue #f3f3f3;
    border-style: solid;
    border-width: 10px;
    display: flex;
    height: 80px;
    justify-content: center;
    width: 80px;
}

.loader div {
    animation: spin2 1s linear infinite;
    border-bottom: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #f3f3f3;
    border-color: blue #f3f3f3;
    border-style: solid;
    border-width: 7px;
    height: 50px;
    width: 50px;
}

.title-page {
    color: #000;
    font-size: 20px;
    margin-bottom: 0;
}

.badge_profile {
    background-color: #52c41a;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 14px;
    position: absolute;
    right: -3px;
    top: 7px;
    width: 14px;
}

.badge_profile:after {
    animation: ripple 1.2s ease-in-out infinite;
    border: 3px solid #52c41a;
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

@keyframes ripple {
    0% {
        opacity: 1;
        transform: scale(0.7);
    }

    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-1turn);
    }
}

.ant-message-custom-content {
    display: flex;
}

.billing-payment-method_item {
    align-items: center;
    background-color: #f0f2f4;
    border-radius: 8px;
    display: flex;
    height: 100%;
    margin-right: 0;
    padding: 12px;
    width: 100%;
}

.button_amount_billing {
    align-items: center;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    color: var(--sub_text);
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    padding: 12px 10px;
    width: 100%;
}

.button_amount_billing:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
}

.button_amount_billing[data-active='true'] {
    border-color: var(--primary);
    box-shadow: 0 0 7px rgba(9, 110, 255, 0.3);
    color: var(--primary);
}

.button_amount_billing-input input {
    color: var(--sub_text) !important;
    font-size: 18px !important;
    padding: 0 !important;
    border: none !important;
    text-align: center !important;
    background: transparent !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}

.button_amount_billing .button_amount_billing-input {
    color: var(--sub_text) !important;
    font-size: 18px !important;
    padding: 0 !important;
    border: none !important;
    text-align: center !important;
    background: transparent !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}

.button_amount_billing .button_amount_billing-input:focus,
.button_amount_billing .button_amount_billing-input:focus-visible {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.button_amount_billing .button_amount_billing-input:focus,
.button_amount_billing .button_amount_billing-input:focus-within {
    box-shadow: none !important;
}

.button_amount_billing-input .ant-input-suffix b {
    font-size: 18px !important;
}

.container-make-payment {
    background-color: #f0f2f4;
    min-height: 100%;
}

.container-make-payment .ant-card-head {
    padding: 10px 12px;
}

.bg-warning {
    background: var(--pending_color) !important;
}

.box-header-icon {
    background-color: #f3f3f4 !important;
    border: 1px solid #fff;
}
